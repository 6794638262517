<template>
  <van-notice-bar
    left-icon="volume-o"
    text="使用前请先关注“蜂动”公众号，及时接收订单消息，点击关注!"
    @click="mpQr = true"
  />
  <van-popup v-model:show="mpQr">
    <van-image width="200" height="200" src="https://qiniu-fengdong-shop.feddon.com/common-logo.jpg" />
  </van-popup>
  <div style="background:#fff;padding:15px;">
    <van-row>
      <van-col span="6">
        <van-image
          radius="5"
          width="4rem"
          height="4rem"
          :src="store.logo"
        />
      </van-col>
      <van-col span="18">
        <p>{{ store.name }}</p>
        <p style="color:#999;margin:0 0 0 0;">
          <small>上架商品 {{ count }} 件</small>
        </p>
        <van-tag plain type="primary">店铺ID {{ store.storeId }}</van-tag>&nbsp;
        <van-tag plain type="primary" @click="onGoAuthentication">
          <template v-if="store.authenticationStatus === 'UNCERTIFIED'">
            {{ store.authenticationStatusText }}
          </template>
          <template v-else-if="store.authenticationStatus === 'VERIFYING'">
            {{ store.authenticationStatusText }}
          </template>
          <template v-else-if="store.authenticationStatus === 'CERTIFIED'">
            {{ store.authenticationStatusText }}
          </template>
        </van-tag>
      </van-col>
    </van-row>
  </div>
  <van-cell-group title="营收概览" />
  <van-grid :column-num="3">
    <van-grid-item :to="{ name: 'OrderList', query: { tab: 1 } }">
      <p>
        <small>{{ pending }} 笔</small>
      </p>
      <p>
        <small>待发货</small>
      </p>
    </van-grid-item>
    <van-grid-item :to="{ name: 'OrderList', query: { tab: 5 } }">
      <p>
        <small>{{ pendingRefund }} 笔</small>
      </p>
      <p>
        <small>待退款</small>
      </p>
    </van-grid-item>
    <van-grid-item :to="{ name: 'Fund', query: { tab: 5 } }">
      <p>
        <small>{{ v3 }} 元</small>
      </p>
      <p>
        <small>昨日收益</small>
      </p>
    </van-grid-item>
  </van-grid>
  <van-cell-group title="常用功能" />
  <van-grid :column-num="4">
    <van-grid-item
      icon="shop-o"
      text="店铺设置"
      :to="{ name: 'Settings'}"
    />
    <van-grid-item
      icon="point-gift-o"
      text="商品管理"
      :to="{ name: 'Product'}"
    />
    <van-grid-item
      icon="orders-o"
      text="订单管理"
      :to="{ name: 'OrderList'}"
    />
    <van-grid-item
      icon="chart-trending-o"
      text="订单数据"
      to="/order/sum"
    />
    <van-grid-item
      icon="friends-o"
      text="会员管理"
      :to="{ name: 'Member'}"
    />
    <van-grid-item
      icon="friends-o"
      text="员工管理"
      :to="{ name: 'StaffList'}"
    />
    <!-- <van-grid-item
      icon="friends-o"
      text="技师"
      :to="{ name: 'Technician'}"
    />
    <van-grid-item
      icon="friends-o"
      text="股东"
      to="/shareholder"
    />
    <van-grid-item
      icon="friends-o"
      text="销售员"
      to="/salesperson"
    /> -->
    <van-grid-item
      icon="friends-o"
      text="邀请关系"
      :to="{ name: 'Invite'}"
    />
    <van-grid-item
      icon="cluster-o"
      text="商品标签"
      :to="{ name: 'Tag'}"
    />
    <!-- <van-grid-item
      icon="after-sale"
      text="满减"
      to="/reduction"
    /> -->
    <van-grid-item
      icon="more-o"
      text="全站链接"
      :to="{ name: 'Link'}"
    />
    <!-- <van-grid-item
      icon="friends-o"
      text="会员"
    />
    <van-grid-item
      icon="balance-pay"
      text="储值卡"
      to="/deposit"
    />
    <van-grid-item
      icon="shopping-cart-o"
      text="货源代销"
    /> -->
    <van-grid-item
      icon="balance-pay"
      text="会员储值"
      :to="{ name: 'Deposit'}"
    />
    <van-grid-item
      icon="award-o"
      text="品牌绑定"
      :to="{ name: 'BindChain'}"
    />
    <van-grid-item
      icon="balance-o"
      text="自由付"
      :to="{ name: 'FreePay' }"
    />
    <van-grid-item
      icon="location-o"
      text="线下网点"
      :to="{ name: 'Outlet'}"
    />
    <van-grid-item
      icon="good-job-o"
      text="全部评论"
      :to="{ name: 'Comment'}"
    />
    <van-grid-item
      icon="balance-list-o"
      text="分红统计"
      :to="{ name: 'CommentStatistics'}"
    />
    <van-grid-item
      icon="manager-o"
      text="技师管理"
      :to="{ name: 'TechnicianManagement'}"
    />
  </van-grid>
  <!--
  <van-cell-group title="店铺管理" />
  <van-grid :column-num="4">
    <van-grid-item
      icon="brush-o"
      text="店铺装修"
      to="/diy/home"
    />
    <van-grid-item
      icon="more-o"
      text="页面链接"
      to="/store/link"
    />
  </van-grid>
  -->
  <rights />
  <van-dialog v-model:show="accountShow" title="汇款账户验证信息">
    <van-cell-group inset>
      <van-field
        v-model="account_validation.account_name"
        readonly
        label-width="7em"
        input-align="right"
        label="付款户名"
      />
      <van-field
        v-model="account_validation.account_no"
        readonly
        label-width="7em"
        input-align="right"
        label="付款卡号"
      />
      <van-field
        v-model="account_validation.pay_amount"
        readonly
        label-width="7em"
        input-align="right"
        label="汇款金额"
      />
      <van-field
        v-model="account_validation.destination_account_number"
        readonly
        label-width="7em"
        input-align="right"
        label="收款卡号"
      />
      <van-field
        v-model="account_validation.destination_account_name"
        readonly
        label-width="7em"
        input-align="right"
        label="收款户名"
      />
      <van-field
        v-model="account_validation.destination_account_bank"
        readonly
        label-width="7em"
        input-align="right"
        label="开户银行"
      />
      <van-field
        v-model="account_validation.city"
        readonly
        label-width="7em"
        input-align="right"
        label="省市信息"
      />
      <van-field
        v-model="account_validation.remark"
        readonly
        label-width="7em"
        input-align="right"
        label="备注信息"
      />
      <van-field
        v-model="account_validation.deadline"
        readonly
        label-width="7em"
        input-align="right"
        label="汇款截止时间"
      />
    </van-cell-group>
  </van-dialog>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import Rights from '@/components/Rights.vue'
import dayjs from 'dayjs'
import { Dialog } from 'vant'

export default {
  components: {
    Rights
  },
  props: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const state = reactive({
      storeId: Cookies.get('storeId'),
      balance: 0,
      store: {},
      yincome: 0,
      tincome: 0,
      count: 0,
      orderTypeShow: false,
      actions: [],
      pending: 0,
      pendingRefund: 0,
      grouponPending: 0,
      grouponPendingRefund: 0,
      yesterdayIncome: 0,
      mpQr: false,
      v3: 0,
      accountShow: false,
      account_validation: {}
    })
    const vantDialog = (obj, callback) => {
      Dialog.confirm(obj).then(() => {
        callback()
      })
    }
    const init = () => {
      post('/store.get', {
        storeId: state.storeId
      }).then(res => {
        state.store = res.data
        if (!res.data.storeMerchant) {
          // vantDialog({
          //   message: '微信支付尚未申请，目前无法使用店铺',
          //   confirmButtonText: '前往申请',
          //   showCancelButton: false
          // }, () => {
          //   useRouter.push({ path: '/applyWechatPay' })
          // })
        }
        if (res.data.storeMerchant && res.data.storeMerchant.applyment_state !== 'FINISH') {
          if (res.data.storeMerchant.applyment_state === 'CHECKING') {
            vantDialog({
              message: '资料校验中，请耐心等待',
              showConfirmButton: false,
              showCancelButton: false
            })
          }
          if (res.data.storeMerchant.applyment_state === 'AUDITING') {
            vantDialog({
              message: '资料审核中，请耐心等待',
              showConfirmButton: false,
              showCancelButton: false
            })
          }
          if (res.data.storeMerchant.applyment_state === 'ACCOUNT_NEED_VERIFY') {
            vantDialog({
              message: '账户待验证，请耐心等待',
              confirmButtonText: '查看验证信息',
              showCancelButton: false
            }, () => {
              state.account_validation = JSON.parse(res.data.storeMerchant.account_validation)
              state.accountShow = true
            })
          }
          if (res.data.storeMerchant.applyment_state === 'REJECTED') {
            vantDialog({
              message: `申请已被驳回，驳回原因：${JSON.parse(res.data.storeMerchant.audit_detail[0].reject_reason)}`,
              confirmButtonText: '重新申请',
              showCancelButton: false
            })
          }
          if (res.data.storeMerchant.applyment_state === 'CANCELED') {
            vantDialog({
              message: '申请已作废，请重新申请',
              confirmButtonText: '重新申请',
              showCancelButton: false
            })
          }
          if (res.data.storeMerchant.applyment_state === 'FROZEN') {
            vantDialog({
              message: '申请已冻结',
              confirmButtonText: '重新申请',
              showCancelButton: false
            })
          }
          if (res.data.storeMerchant.applyment_state === 'NEED_SIGN') {
            vantDialog({
              message: '入驻申请待签约，请前往签约',
              confirmButtonText: '前往签约',
              showCancelButton: false
            }, () => {
              window.location.href = res.data.storeMerchant.sign_url
            })
          }
        }
      })
      post('/order.list', {
        storeId: state.storeId,
        status: 'WAIT_DELIVER'
      }).then(res => {
        state.pending = res.data.totalElements
      })
      post('/order.list', {
        storeId: state.storeId,
        status: 'REFUNDING'
      }).then(res => {
        state.pendingRefund = res.data.totalElements
      })
      post('/product.list', {
        storeId: state.storeId,
        onSale: true
      }).then(res => {
        state.count = res.data.totalElements
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        status: 'COMPLETED',
        minCompleteTime: dayjs().subtract(1, 'day').set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss'),
        maxCompleteTime: dayjs().subtract(1, 'day').set('hour', 23).set('minute', 59).set('second', 59).format('YYYY-MM-DD HH:mm:ss')
      }).then(res => {
        state.v3 = res.data.payAmount
      })
    }
    const onGoAuthentication = () => {
      useRouter.push({ name: 'Authentication' })
    }
    const onLogout = () => {
      for (const item of Object.keys(Cookies.get())) {
        Cookies.remove(item)
      }
      useRouter.push({ path: '/login' })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      init,
      onGoAuthentication,
      onLogout
    }
  }
}
</script>

<style scoped>
</style>
